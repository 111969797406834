<template>
  <Delegation
    ref="delegation"
    :delegator="delegator"
    :delegatee="delegatee"
    :delegation="delegation"
  />
</template>

<script>
export default {
  components: {
    Delegation: () => import('./delegation'),
  },
  data(){
    return {
      delegator: {},
      delegatee: {},
      delegation: {},
    }
  },
  methods: {
    toggle(payload){
      this.$refs.delegation.toggle(payload.delegationdetail)
      if(payload.delegationdetail.delegationReason === 'vacant'){
        this.delegator = this.getDesignationInformation(payload.delegator)
        this.delegatee = this.getEmployeeInformation(payload.delegatee)
      } else {
        this.delegator = this.getEmployeeInformation(payload.delegator)
        this.delegatee = this.getEmployeeInformation(payload.delegatee)
      }
    },
    getDesignationInformation: (payload) => ({
      ...payload,
      fname: payload.name,
      designation: payload.name,
      orgFunction: payload.orgFunction?.name,
      reportingTo: payload.reportsTo?.name,
      level: payload.level?.name,
    }),
    getEmployeeInformation: (payload) => ({
      ...payload,
      orgFunction: payload.orgFunction?.name,
      designation: payload.userDesignation?.name,
      level: payload.userLevel?.name,
      reportingTo: payload.userReportingTo?.name,
      officeAddress: payload.employeeOffice?.name,
    }),
  }
}
</script>

<style scoped>
.rightSidebar {
  width: 520px !important;
}
</style>
